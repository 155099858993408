import React, { useState, useRef, useEffect } from "react";
import "../../style/Navbar/navbar.scss";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useAtom } from "jotai";
import { DarkModeState ,CurrentSongRadio} from "../../Jotai/index";
import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';
import MusicPlayerTooltip from '../MusicPlayerTooltip/MusicPlayerTooltip'
function Navbar() {
  const [mobileNavbar, setmobileNavbar] = useState(false);
  const [darkMode, setDarkMode] = useAtom(DarkModeState);
  const [radioName, setradioName] = useState("Radio Online");
  const [radioNameMenu, setradioNameMenu] = useState(false);
  // const [playBtn, setplayBtn] = useState(false);
  const [station, setStation] = useState({ 
    label: "Radio Online", 
    name: 'RadioOnline', 
    url: process.env.REACT_APP_22WESTRADIOONLINE,
    xmlUrl: 'https://np.tritondigital.com/public/nowplaying?mountName=22WESTRADIOONLINE&numberToFetch=10&eventType=track'
  });  
  const [volume, setVolume] = useState(0.5);
  const [isMuted, setIsMuted] = useState(true);
  const [prevVolume, setPrevVolume] = useState(volume);


  const [isPlaying, setIsPlaying] = useState(false);
  const volumeControlRef = useRef(null);
  const [showVolumeControl, setShowVolumeControl] = useState(false);
  const [currentSong, setCurrentSong] = useState({ title: '', artist: '', albumArt: '' });
  const [currentSongRadio, setCurrentSongRadio] = useAtom(CurrentSongRadio);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleMouseEnter = () => setIsTooltipVisible(true);
  const handleMouseLeave = () => setIsTooltipVisible(false);
  const location = useLocation();
  // console.log(process.env.REACT_APP_22WESTRADIOONLINE);
  const audioRef = useRef(null);
 
  const handleClickOutside = (e) => {
    if (volumeControlRef.current && !volumeControlRef.current.contains(e.target)) {
      // Clicked outside the volume control, hide it
      setShowVolumeControl(false);
    }
  };
  const titleRef = useRef(null);
  const artistRef = useRef(null);

  // Function to add or remove marquee based on content width
  const checkForMarquee = (ref) => {
    if (ref.current && ref.current.scrollWidth > ref.current.clientWidth) {
      ref.current.classList.add('marquee');
      ref.current.classList.remove('content');
    } else {
      ref.current.classList.remove('marquee');
      ref.current.classList.add('content');
    }
  };

  useEffect(() => {
    // Check for marquee when the song changes
    checkForMarquee(titleRef);
    checkForMarquee(artistRef);
  }, [currentSong]); 

  useEffect(() => {
    // Attach click event listener to the document
    document.addEventListener('click', handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []); 

  const options = [
    {  name: "RadioOnline", 
      value: "RadioOnline", 
      label: "Radio Online", 
      url: process.env.REACT_APP_22WESTRADIOONLINE,
      xmlUrl: 'https://np.tritondigital.com/public/nowplaying?mountName=22WESTRADIOONLINE&numberToFetch=10&eventType=track'
    },
    { 
      name: "FM", 
      value: "FM", 
      label: "HD3 FM", 
      url: process.env.REACT_APP_22WESTFM,
      xmlUrl: 'https://np.tritondigital.com/public/nowplaying?mountName=22WESTFM&numberToFetch=10&eventType=track'
    }

  ];

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty("--background-color", darkMode ? "#262626" : "#fff");
    root.style.setProperty("--text-color", darkMode ? "#fff" : "#000");
  }, [darkMode]);

  useEffect(() => {
    const savedMode = localStorage.getItem("darkMode");
    // console.log(savedMode);
    if (savedMode !== null) {
      setDarkMode(savedMode === "true");
    } else {
      const prefersDark = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      setDarkMode(prefersDark);
    }
  }, [setDarkMode]);

  const toggleTheme = () => {
    setDarkMode((data) => {
      const dataNg = !data;
      localStorage.setItem("darkMode", dataNg.toString());
      return dataNg;
    });
  };
  const toggleMute = async () => {
    // Start playing if it's the first interaction and the radio is not playing
    if (!isPlaying) {
      try {
        await audioRef.current.play();
        setIsPlaying(true); // Update the playing state
      } catch (error) {
        console.error('Error playing audio:', error);
        // Handle the error (show a message to the user, for instance)
      }
    }
    
    // Proceed with muting or unmuting
    if (isMuted) {
      // Unmute
      audioRef.current.volume = prevVolume;
      setVolume(prevVolume);
    } else {
      // Mute
      setPrevVolume(volume);
      audioRef.current.volume = 0;
      setVolume(0);
    }
    setIsMuted(!isMuted);
  };
  const toggleVolumeControl = () => {
    setShowVolumeControl(!showVolumeControl);
  };
const handleStationChange = async (selectedOption) => {
  if (isPlaying) {
    try {
      await audioRef.current.pause();
      await setStation({ 
        name: selectedOption.label, 
        url: selectedOption.url,
        xmlUrl: selectedOption.xmlUrl
      });      
      await audioRef.current.load(); // Load the new source
      await audioRef.current.play(); // Play the new source
     
      setradioName(selectedOption.label); 
    } catch (error) {
      console.error('Error playing audio:', error);
    }
  } else {
      setStation({ 
          name: selectedOption.name, 
          url: selectedOption.url,
          xmlUrl: selectedOption.xmlUrl
        });    
      setradioName(selectedOption.label);
  }
};

const fetchAlbumCover = async (songTitle, artistName) => {
  try {
    const response = await axios.get(`https://itunes.apple.com/search?term=${encodeURIComponent(songTitle + ' ' + artistName)}&media=music&entity=album&limit=1`);
    const albums = response.data.results;
    if (albums.length > 0) {
      return albums[0].artworkUrl100; // URL of the album cover
    }
    return ''; // No album cover found
  } catch (error) {
    console.error('Error fetching album cover:', error);
    return '';
  }
};
 

  const handleTogglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying((prev) => !prev);
  };

  // const handleVolumeChange = (e) => {
  //   const newVolume = e.target.value / 100;
  //   setVolume(newVolume);
  //   audioRef.current.volume = newVolume;
  // };
  const handleVolumeChange = (e) => {
    const newVolume = e.target.value / 100;
    setVolume(newVolume);
    audioRef.current.volume = newVolume;
    if (isMuted && newVolume > 0) {
      setIsMuted(false);
    }
  };
  const fetchNowPlaying = async () => {
    try {
      const response = await axios.get(station.xmlUrl);
      const xmlData = response.data;
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xmlData, "text/xml");
  
      const nowPlayingList = xmlDoc.getElementsByTagName('nowplaying-info');
      if (nowPlayingList.length > 0) {
        const nowPlayingInfo = nowPlayingList[0];
        const properties = nowPlayingInfo.getElementsByTagName('property');
  
        let title = '';
        let artist = '';
  
        for (let prop of properties) {
          if (prop.getAttribute('name') === 'cue_title') {
            title = prop.textContent;
          } else if (prop.getAttribute('name') === 'track_artist_name') {
            artist = prop.textContent;
          }
        }


        const albumArt = await fetchAlbumCover(title, artist);
        setCurrentSong({ title, artist, albumArt });
        if(station.name==="RadioOnline"){
          // console.log("AAAAAAA",true)
          setCurrentSongRadio({ title, artist, albumArt })
        }
      }
    } catch (error) {
      console.error('Error fetching now playing info:', error);
    }
  };
  
  useEffect(() => {
    if (station.xmlUrl) {
      fetchNowPlaying();
      const intervalId = setInterval(fetchNowPlaying, 30000); // refresh every 30 seconds

      return () => clearInterval(intervalId); // cleanup interval on component unmount
    }
  }, [station.xmlUrl]);
  
  

  return (
    <div className="navBarMain">
      <div className="titleBarRadio">
        <div className="titleBarRadioleft">
          <div className="radioSelectionBtn">
            <div
              className="selectionTagName"
              onClick={() => {
                setradioNameMenu(!radioNameMenu);
              }}
            >
              {radioName}
              <img
                src="/image/logo/DownArrow.png"
                alt="downarr"
                className={radioNameMenu ? "uparowCss" : "downarowCss"}
              ></img>
            </div>
            <div
              className={
                radioNameMenu ? "selectionTagMenuON" : "selectionTagMenuOFF"
              }
            >
              {options.map((option) => {
                return (
                  <div
                    key={option.value}
                    className="selectionTagMenuItem"
                    onClick={() => {
                      setradioNameMenu(!radioNameMenu);
                      handleStationChange(option);
                      setradioName(option.label);
                    }}
                  >
                    {option.label}
                  </div>
                );
              })}
            </div>
          </div>
       
        <div className="radioNameandStart">
          <div class="grid-container"   onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <MusicPlayerTooltip currentSong={currentSong} isVisible={isTooltipVisible} />
            <div className="grid-item flexCenter">
              {currentSong.albumArt ? (
                <img src={currentSong.albumArt} alt="Album Art" className="album-art" width={50} />
              ) : (
                <img src="/image/DefaultAlbumArt.png" alt="Default Album Art" className="album-art" width={50} />
              )}
            </div>
            <div class="grid-itemBox">
              <div class="grid-item titleName content" ref={titleRef}>
              {/* <Tooltip title={currentSong.title}>  <span>{currentSong.title}</span></Tooltip> */}
                <span>{currentSong.title}</span>
              </div>
              <div class="grid-item artistName content" ref={artistRef}>
              {/* <Tooltip title={currentSong.artist}>   <span>{currentSong.artist}</span></Tooltip> */}
              <span>{currentSong.artist}</span>
              </div>
            </div>
            <div class="grid-item flexCenter">
              <audio ref={audioRef} src={station.url} />
              {/* OLD Player Version.
              <img
                src={isPlaying ? "/image/logo/pause.png" : "/image/logo/play-button.png"}
                alt="play"
                className="playButton"
                onClick={handleTogglePlay}
              /> */}
              <img
                src={isMuted ? "/image/logo/play-button.png" : "/image/logo/pause.png"}
                alt={isMuted ? "Unmute" : "Mute"}
                className="playButton"
                onClick={toggleMute}
              />
            </div>
            <div className="grid-item flexCenter">

                <img
                  src="/image/logo/volume-up-interface-symbol.png"
                  alt="volume"
                  className="playButton"
                  onClick={toggleVolumeControl}
                  ref={volumeControlRef}
                />
                <div

               
                  className={`volume-control ${
                    showVolumeControl ? "show" : ""
                  }`}
                >
                  <input
                    type="range"
                    min={0}
                    max={100}
                    value={volume * 100}
                    onChange={handleVolumeChange}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="donateANContactBtn">
            <a href="https://commerce.cashnet.com/LBS22WESTMEDIA" target="_blank" rel="noopener noreferrer">
              Donate
            </a>
          </div>

          <div className="donateANContactBtn">
            <a href="https://csulb.qualtrics.com/jfe/form/SV_cBfXNQ8COQGqA7k" target="_blank" rel="noopener noreferrer">
          Contact Us
            </a>
            </div>
          <NavLink to={"/magazineSearch"}>
            <div className="donateANContactBtn">
              <img
                src="/image/logo/magnifying-glass.png"
                alt="search"
                className="searchBtn"
              />
            </div>
          </NavLink>
        </div>

        <div className="titleBarRadioright">
          <div className="DarkModeBtn" onClick={toggleTheme}>
            <img
              src="/image/logo/sun.png"
              alt="sun"
              className="sunBtn leftMoon "
            />
            <img
              src="/image/logo/moon.png"
              alt="moon"
              className="sunBtn rightSun"
            />
            <div className={darkMode ? "roundBtnLeft" : "roundBtnRight"}></div>
          </div>
        </div>
      </div>

      <div className="navBarMain_card">
        <div className="navBarMainLOGO">
        <NavLink to={"/"} className={"navLinkCSSDesign"}>
          <img src="/image/logo/22WestLogo.svg" alt="logo" className="" />
          </NavLink>
        </div>
        <div
          className={
            darkMode ? "menuBar menuBar-dark" : "menuBar menuBar-light"
          }
        >
          <NavLink to={"/"} className={"navLinkCSSDesign"}>
            <div
              className={
                darkMode
                  ? "menuBtn w_color " +
                    (location.pathname === "/" ? " activeClassNav " : "")
                  : "menuBtn " +
                    (location.pathname === "/" ? " activeClassNav" : "")
              }
            >
              Home
            </div>
          </NavLink>
          <NavLink to={"/radio"} className={"navLinkCSSDesign"}>
            <div
              className={
                darkMode
                  ? "menuBtn w_color " +
                    (location.pathname === "/radio" ? " activeClassNav " : "")
                  : "menuBtn " +
                    (location.pathname === "/radio" ? " activeClassNav" : "")
              }
            >
              Radio
            </div>
          </NavLink>
          <NavLink to={"/Television"} className={"navLinkCSSDesign"}>
            {" "}
            <div
              className={
                darkMode
                  ? "menuBtn w_color " +
                    (location.pathname === "/Television" ? " activeClassNav " : "")
                  : "menuBtn " +
                    (location.pathname === "/Television" ? " activeClassNav" : "")
              }
            >
              Television
            </div>
          </NavLink>
          <NavLink to={"/magazine"} className={"navLinkCSSDesign"}>
            <div
              className={
                darkMode
                  ? "menuBtn w_color " +
                    (location.pathname === "/magazine"
                      ? " activeClassNav "
                      : "")
                  : "menuBtn " +
                    (location.pathname === "/magazine"
                      ? " activeClassNav"
                      : "")
              }
            >
              Magazine
            </div>
          </NavLink>

          <NavLink to={"/advertise"} className={"navLinkCSSDesign"}>
            <div
              className={
                darkMode
                  ? "menuBtn w_color " +
                    (location.pathname === "/advertise"
                      ? " activeClassNav "
                      : "")
                  : "menuBtn " +
                    (location.pathname === "/advertise"
                      ? " activeClassNav"
                      : "")
              }
            >
              Advertisements
            </div>
          </NavLink>

          <NavLink to={"/about"} className={"navLinkCSSDesign"}>
            {" "}
            <div
              className={
                darkMode
                  ? "menuBtn w_color " +
                    (location.pathname === "/about" ? " activeClassNav " : "")
                  : "menuBtn " +
                    (location.pathname === "/about" ? " activeClassNav" : "")
              }
            >
              About
            </div>
          </NavLink>
        </div>

        <div className="socialMediaBtns">
          <Link to={"https://www.instagram.com/22WestLB/"} target="_blank">
            <img
              src="/image/logo/Insta.svg"
              className="iconSocialMedia"
              alt="insta"
            />
          </Link>
          <Link to={"https://www.tiktok.com/@22westlb"} target="_blank">
            <img
              src="/image/logo/tiktok.svg"
              alt="tiktok"
              className="iconSocialMedia"
            />
          </Link>
          <Link to={"https://www.youtube.com/@22WestTV"} target="_blank">
            <img
              src="/image/logo/youtube.svg"
              alt="youtube"
              className="iconSocialMedia"
            />
          </Link>
        </div>

        <div className="mobileMenuBarBtn">
          <img
            src="/image/logo/menuicon.svg"
            alt="lomenuicongo"
            className="menuicon"
            onClick={() => {
              setmobileNavbar((data) => {
                return !data;
              });
            }}
          />
        </div>

        <div className={mobileNavbar ? "menuBarMobile" : "menuBarMobileFalse"}>
          <NavLink
            to={"/"}
            className={"navLinkCSSDesign"}
            onClick={() => {
              setmobileNavbar((data) => {
                return !data;
              });
            }}
          >
            <div className="menuBtn">Home</div>
          </NavLink>
          <NavLink
            to={"/radio"}
            className={"navLinkCSSDesign"}
            onClick={() => {
              setmobileNavbar((data) => {
                return !data;
              });
            }}
          >
            <div className="menuBtn">Radio</div>
          </NavLink>
          <NavLink
            to={"/Television"}
            className={"navLinkCSSDesign"}
            onClick={() => {
              setmobileNavbar((data) => {
                return !data;
              });
            }}
          >
            {" "}
            <div className="menuBtn">Television</div>
          </NavLink>
          <NavLink
            to={"/magazine"}
            className={"navLinkCSSDesign"}
            onClick={() => {
              setmobileNavbar((data) => {
                return !data;
              });
            }}
          >
            <div className="menuBtn">Magazine</div>
          </NavLink>
          <NavLink
            to={"/advertise"}
            className={"navLinkCSSDesign"}
            onClick={() => {
              setmobileNavbar((data) => {
                return !data;
              });
            }}
          >
            <div className="menuBtn">Advertisements</div>
          </NavLink>
          <NavLink
            to={"/about"}
            className={"navLinkCSSDesign"}
            onClick={() => {
              setmobileNavbar((data) => {
                return !data;
              });
            }}
          >
            <div className="menuBtn">About</div>
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
