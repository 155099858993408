import React from "react";
import "../../style/About/index.scss";
import Navbar from "../../components/navbar/Navbar";
import Aboutbanner from "../../components/Aboutbanner/Aboutbanner";
import AboutStaff from "../../components/AboutStaff/AboutStaff";
import { useState } from "react";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import { useAtom } from "jotai";
import { DarkModeState,eventsAtom } from "../../Jotai/index";
import { NavLink } from "react-router-dom";

const About = () => {
  const Data = [
    {
      title: "About Us",
      imageLink: "image/Advertisements/22w-team-retreat-fa24.jpg",
      text: "22 West is a student-run, student-driven digital media center at California State University, Long Beach. We encompass a production company, radio station, and magazine, providing a space for students to express themselves and gain hands-on experience in media production. Any student with an interest in media is welcome to join the team, regardless of experience level. Visit us on the bottom floor of the USU to say hi!",
    },
  ];

  const staffData = [
    
    {
      gif: "image/staff/2.gif",
      img: "image/staff/2.svg",
      name: "Chloe Clemmons",
      status: "Administrative Assistant",
      outline: "",
      dribble: "",
      instagram: "",
      linkedin: "",
      tiktok: "",
      soundcloud: "",
      substack: "",
      web: "",
      youtube: "",
      category: "Administrative",
    },
    {
      gif: "image/staff/3.gif",
      img: "image/staff/3.svg",
      name: "Andres Avila",
      status: "Administrative Assistant",
      outline: "",
      dribble: "",
      instagram: "",
      linkedin: "",
      tiktok: "",
      soundcloud: "",
      substack: "",
      web: "",
      youtube: "",
      category: "Administrative",
    },
    // {
    //   gif: "image/staff/4.gif",
    //   img: "image/staff/4.svg",
    //   name: "Panhavatey Bun ",
    //   status: "Sales & Advertising Manager",
    //   outline: "",
    //   dribble: "",
    //   instagram: "",
    //   linkedin: "",
    //   tiktok: "",
    //   soundcloud: "",
    //   substack: "",
    //   web: "",
    //   youtube: "",
    //   category: "Marketing",
    // },
    {
      gif: "image/staff/6.gif",
      img: "image/staff/6.svg",
      name: "Jadyen Arana",
      status: "Social Media Assistant",
      outline: "",
      dribble: "",
      instagram: "",
      linkedin: "",
      tiktok: "",
      soundcloud: "",
      substack: "",
      web: "",
      youtube: "",
      category: "Marketing",
    },
    {
      gif: "image/staff/Jerry Reynoso_Sports Director.gif",
      img: "image/staff/Jerry Reynoso_Sports Director-0000.jpg",
      name: "Jerry Reynoso",
      status: "Sports Director",
      outline: "",
      dribble: "",
      instagram: "",
      linkedin: "",
      tiktok: "",
      soundcloud: "",
      substack: "",
      web: "",
      youtube: "",
      category: "Radio",
    },
    {
      gif: "image/staff/Payton Smith_Advertising Associate.gif",
      img: "image/staff/Payton Smith_Advertising Associate-0000.jpg",
      name: "Payton Smith",
      status: "Advertising Associate",
      outline: "",
      dribble: "",
      instagram: "",
      linkedin: "",
      tiktok: "",
      soundcloud: "",
      substack: "",
      web: "",
      youtube: "",
      category: "Magazine",
    },
   
    {
      gif: "image/staff/ALANA LOINAZ_COPY EDITOR.gif",
      img: "image/staff/ALANA LOINAZ_COPY EDITOR-0000.jpg",
      name: "Alana Loinaz",
      status: "Lead Copy Editor",
      outline: "",
      dribble: "",
      instagram: "",
      linkedin: "",
      tiktok: "",
      soundcloud: "",
      substack: "",
      web: "",
      youtube: "",
      category: "Magazine",
    },
    {
      gif: "image/staff/CAROLINE BAE_ART DIRECTOR.gif",
      img: "image/staff/CAROLINE BAE_ART DIRECTOR-0000.jpg",
      name: "Caroline Bae",
      status: "Art Director",
      outline: "",
      dribble: "",
      instagram: "",
      linkedin: "",
      tiktok: "",
      soundcloud: "",
      substack: "",
      web: "",
      youtube: "",
      category: "Magazine",
    },
    {
      gif: "image/staff/JENSEN PUCKETT_EDITOR IN CHIEF.gif",
      img: "image/staff/JENSEN PUCKETT_EDITOR IN CHIEF-0000.jpg",
      name: "Jensen Puckett",
      status: "Editor In Chief",
      outline: "",
      dribble: "",
      instagram: "",
      linkedin: "",
      tiktok: "",
      soundcloud: "",
      substack: "",
      web: "",
      youtube: "",
      category: "Magazine",
    },
    {
      gif: "image/staff/22-west-watermark-transparent.png",
      img: "image/staff/22-west-watermark-transparent.png",
      name: "Gia Krupens",
      status: "Managing Editor",
      outline: "",
      dribble: "",
      instagram: "",
      linkedin: "",
      tiktok: "",
      soundcloud: "",
      substack: "",
      web: "",
      youtube: "",
      category: "Magazine",
    },
      {
      gif: "image/staff/vishwanath-Bhat-Padukudru.jpg",
      img: "image/staff/vishwanath-Bhat-Padukudru.jpg",
      name: "Vishwanath Bhat Padukudru",
      status: "Graduate Assistant",
      outline: "",
      dribble: "",
      instagram: "",
      linkedin: "",
      tiktok: "",
      soundcloud: "",
      substack: "",
      web: "",
      youtube: "",
      category: "Administrative",
    },
    // {
    //   gif: "image/staff/22-west-watermark-transparent.png",
    //   img: "image/staff/22-west-watermark-transparent.png",
    //   name: "William Liang",
    //   status: "Staff Photographer",
    //   outline: "",
    //   dribble: "",
    //   instagram: "",
    //   linkedin: "",
    //   tiktok: "",
    //   soundcloud: "",
    //   substack: "",
    //   web: "",
    //   youtube: "",
    //   category: "Media",
    // },
    {
      gif: "image/staff/22-west-watermark-transparent.png",
      img: "image/staff/22-west-watermark-transparent.png",
      name: "Havni Rami",
      status: "Performance DJ",
      outline: "",
      dribble: "",
      instagram: "",
      linkedin: "",
      tiktok: "",
      soundcloud: "",
      substack: "",
      web: "",
      youtube: "",
      category: "Radio",
    },
    
    {
      gif: "image/staff/Morgan Barela_Production & Imaging Director.gif",
      img: "image/staff/Morgan Barela_Production & Imaging Director-0000.jpg",
      name: "Morgan Barela",
      status: "Production and Imaging Director",
      outline: "",
      dribble: "",
      instagram: "",
      linkedin: "",
      tiktok: "",
      soundcloud: "",
      substack: "",
      web: "",
      youtube: "",
      category: "Radio",
    },
     // {
    //   gif: "image/staff/22-west-watermark-transparent.png",
    //   img: "image/staff/22-west-watermark-transparent.png",
    //   name: "Stephen Renteria",
    //   status: "Music Director",
    //   outline: "",
    //   dribble: "",
    //   instagram: "",
    //   linkedin: "",
    //   tiktok: "",
    //   soundcloud: "",
    //   substack: "",
    //   web: "",
    //   youtube: "",
    //   category: "Radio",
    // },
    {
      gif: "image/staff/SAMANTHA ROSARIO_PROGRAM DIRECTOR (1).gif",
      img: "image/staff/SAMANTHA ROSARIO_PROGRAM DIRECTOR-0000.jpg",
      name: "Samantha Rosario",
      status: "Program Director",
      outline: "",
      dribble: "",
      instagram: "",
      linkedin: "",
      tiktok: "",
      soundcloud: "",
      substack: "",
      web: "",
      youtube: "",
      category: "Radio",
    },
    {
      gif: "image/staff/SESHA SRUNGARAM_CHIEF ENGINEER.gif",
      img: "image/staff/SESHA SRUNGARAM_CHIEF ENGINEER-0000.jpg",
      name: "Sesha Vaishanvi Srungaram",
      status: "Chief Engineer",
      outline: "",
      dribble: "",
      instagram: "",
      linkedin: "",
      tiktok: "",
      soundcloud: "",
      substack: "",
      web: "",
      youtube: "",
      category: "Radio",
    },
    {
      gif: "image/staff/22-west-watermark-transparent.png",
      img: "image/staff/22-west-watermark-transparent.png",
      name: "Kaylee Gallagher",
      status: "Board Operator",
      outline: "",
      dribble: "",
      instagram: "",
      linkedin: "",
      tiktok: "",
      soundcloud: "",
      substack: "",
      web: "",
      youtube: "",
      category: "Radio",
    },
    {
      gif: "image/staff/jeffrey-whitten.jpg",
      img: "image/staff/jeffrey-whitten.jpg",
      name: "Jeffrey Whitten",
      status: "Student Media Manager",
      outline: "",
      dribble: "",
      instagram: "",
      linkedin: "",
      tiktok: "",
      soundcloud: "",
      substack: "",
      web: "",
      youtube: "",
      category: "Administrative",
    },
    {
      gif: "image/staff/taylor-buhler-scott.jpg",
      img: "image/staff/taylor-buhler-scott.jpg",
      name: "Taylor Buhler-Scott",
      status: "Assoc. Dir. of Programs & Comm",
      outline: "",
      dribble: "",
      instagram: "",
      linkedin: "",
      tiktok: "",
      soundcloud: "",
      substack: "",
      web: "",
      youtube: "",
      category: "Administrative",
    },
    // {
    //   gif: "image/staff/22-west-watermark-transparent.png",
    //   img: "image/staff/22-west-watermark-transparent.png",
    //   name: "Lance DeOlden",
    //   status: "Producer",
    //   outline: "",
    //   dribble: "",
    //   instagram: "",
    //   linkedin: "",
    //   tiktok: "",
    //   soundcloud: "",
    //   substack: "",
    //   web: "",
    //   youtube: "",
    //   category: "Video",
    // },
  ];
  const buttonData={
    link:"/history",
    buttonText:"History of 22West Media"
  };

  const [staffDataFil, setstaffDataFil] = useState(staffData);
  const [filcategory, setfilcategory] = useState("All");
  const [darkMode] = useAtom(DarkModeState);

  const selectcategory = (name) => {
    setfilcategory(name);
    setstaffDataFil((data) => {
      return staffData.filter((data, index) => {
        if (name === data.category) {
          return true;
        }
        if (name === "All") {
          return true;
        }
      });
    });
  };

  return (
    <>
      <ScrollToTop />

      <div className="About">
        <Aboutbanner imageData={Data} buttonData={buttonData}/>

        <div className="AboutStaffMenu">
          <div className="AboutStaffMenuNav">
            <div
              onClick={() => {
                selectcategory("All");
              }}
              className={
                filcategory === "All"
                  ? "AboutStaffMenuitem active"
                  : "AboutStaffMenuitem"
              }
            >
              All
            </div>
            <div
              onClick={() => {
                selectcategory("Administrative");
              }}
              className={
                filcategory === "Administrative"
                  ? "AboutStaffMenuitem active"
                  : "AboutStaffMenuitem"
              }
            >
              Administrative
            </div>
            <div
              onClick={() => {
                selectcategory("Magazine");
              }}
              className={
                filcategory === "Magazine"
                  ? "AboutStaffMenuitem active"
                  : "AboutStaffMenuitem"
              }
            >
              Magazine
            </div>
            <div
              onClick={() => {
                selectcategory("Radio");
              }}
              className={
                filcategory === "Radio"
                  ? "AboutStaffMenuitem active"
                  : "AboutStaffMenuitem"
              }
            >
              Radio
            </div>
            <div
              onClick={() => {
                selectcategory("Video");
              }}
              className={
                filcategory === "Video"
                  ? "AboutStaffMenuitem active"
                  : "AboutStaffMenuitem"
              }
            >
              Television
            </div>
            <div
              onClick={() => {
                selectcategory("Marketing");
              }}
              className={
                filcategory === "Marketing"
                  ? "AboutStaffMenuitem active"
                  : "AboutStaffMenuitem"
              }
            >
              Marketing
            </div>
          </div>

          <div className="AboutStaffMenucard">
            {staffDataFil.map((data, index) => {
              return <AboutStaff staffData={data} />;
            })}
          </div>
        </div>

        {/* <div className={darkMode ? "aboutTeamSection darkmode" : "aboutTeamSection"}>
          <div className={darkMode ? "aboutTeamSection_main darkmode" : "aboutTeamSection_main"}>
            <div className="aboutTeamSection_title">ASI Web Development Team</div>
            <div className="aboutTeamSectionData">
              <img src="/image/techteam/techteam.jpg" alt="techteam"></img>

              <div className="techteam_aboutText">
              <div className="textAboutTeamPage" >
              The 22 West Media website, developed by Cal State University computer science graduate students, showcases cutting-edge web technologies. The team, led by Julian Miller, includes Kenil Prakashbhai Vaghasiya, Kunjal Ashvinbhai Patel, Rushabh Shah, and Satyam Sharma. Huy Tran, with Danny Lemos' guidance, designed the site, supported by 22 West Media's student assistants. This project aims to provide a rich, engaging experience and highlight the talents of 22 West Media's student-run team.
              </div>

              <NavLink className="navLinkData" to={"/team"}>
              <div  className="techteam_aboutTextbtn">
              View
              </div>
              </NavLink>

              <div></div>

              </div>

            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default About;
